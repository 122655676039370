import { axios, GetOneResponse } from '@jume/core'

import { profileEndpoints } from './endpoints'
import { Profile } from './interfaces'

const getProfile = async (): GetOneResponse<Profile> => {
  const { data } = await axios.request<Profile>({
    method: 'GET',
    ...profileEndpoints.getInfo(),
    tracing: {
      stream: null,
      module: 'app.profile',
      methodName: 'getProfile',
    },
  })
  return data
}

export const profileApi = {
  getProfile,
}
